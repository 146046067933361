import img_profile from '../../assets/images/icons/btn_profile_n.svg';
import img_info from '../../assets/images/icons/img_info.png';

import btn_popup_n from '../../assets/images/icons/btn_popup_n.svg';
import btn_popup_s from '../../assets/images/icons/btn_popup_s.svg';

import btn_navi_01_p from '../../assets/images/icons/btn_profile_s.svg';

import btn_zoomin_n from '../../assets/images/icons/btn_zoomin_n.svg';
import btn_zoomin_p from '../../assets/images/icons/btn_zoomin_p.svg';
import btn_zoomout_n from '../../assets/images/icons/btn_zoomout_n.svg';
import btn_zoomout_p from '../../assets/images/icons/btn_zoomout_p.svg';

import btn_check_n from '../../assets/images/icons/btn_checkb_n.svg';
import btn_check_s from '../../assets/images/icons/btn_checkb_s.svg';

import btn_down_n from '../../assets/images/icons/btn_down_n.svg';
import btn_down_p from '../../assets/images/icons/btn_down_p.svg';
import btn_next_n from '../../assets/images/icons/btn_next_n.svg';
import btn_next_s from '../../assets/images/icons/btn_next_s.svg';

import icon_popup_1 from '../../assets/images/icons/icon_popup_1.svg';
import icon_popup_2 from '../../assets/images/icons/icon_popup_2.svg';

import btn_gravity_n from '../../assets/images/icons/btn_gravity_n.svg';
import btn_gravity_s from '../../assets/images/icons/btn_gravity_s.svg';

import btn_page_pprev_n from '../../assets/images/icons/btn_page_pprev_n.png';
import btn_page_prev_n from '../../assets/images/icons/btn_page_prev_n.png';
import btn_page_next_n from '../../assets/images/icons/btn_next_n.svg';
import btn_page_nnext_n from '../../assets/images/icons/btn_page_nnext_n.png';

import btn_input_n from '../../assets/images/icons/btn_input_n.svg';
import btn_input_p from '../../assets/images/icons/btn_input_p.svg';
import calendar_n from '../../assets/images/icons/calendar_n.svg';

import tab_icon1_n from '../../assets/images/icons/tab_icon1_n.svg';
import tab_icon1_s from '../../assets/images/icons/tab_icon1_s.svg';
import tab_icon2_n from '../../assets/images/icons/tab_icon2_n.svg';
import tab_icon2_s from '../../assets/images/icons/tab_icon2_s.svg';

import icon_card_1 from '../../assets/images/icons/icon_card_1.svg';
import icon_card_2 from '../../assets/images/icons/icon_card_2.svg';
import icon_card_3 from '../../assets/images/icons/icon_card_3.svg';
import icon_card_4 from '../../assets/images/icons/icon_card_4.svg';

import icon_library from '../../assets/images/icons/icon_library.svg';
import icon_request from '../../assets/images/icons/icon_request.svg'; // 판정요청
import icon_complete from '../../assets/images/icons/icon_complete.svg'; // 판정완료
import icon_micro from '../../assets/images/icons/icon_micro.svg';
import icon_box from '../../assets/images/icons/icon_box.svg';

// 공통

export const ProfileIcon2 = (props) => {
	return (
		<img
			src={props?.selected ? btn_navi_01_p : img_profile}
			width="24px"
			height="24px"
			alt="menu icon"
		/>
	);
};

export const ProfileIcon = () => {
	return <img src={img_profile} width="52px" height="52px" alt="profile icon" />;
};

export const InfoCircleIcon = () => {
	return <img src={img_info} width="26px" height="26px" alt="info icon" />;
};

// 다이얼로그

export const CloseIcon = (props) => {
	return (
		<img
			src={props.selected ? btn_popup_s : btn_popup_n}
			width={props.width ? props.width : '34px'}
			height={props.height ? props.height : '34px'}
			alt="close icon"
			{...props}
		/>
	);
};

// 페이지네이션

export const PageFisrtIcon = () => {
	return <img src={btn_page_pprev_n} width="36px" height="36px" alt={'first page icon'} />;
};

export const PagePreviousIcon = () => {
	return <img src={btn_page_prev_n} width="36px" height="36px" alt={'previous page icon'} />;
};

export const PageNextIcon = () => {
	return <img src={btn_page_next_n} width="36px" height="36px" alt={'next page icon'} />;
};

export const PageLastIcon = () => {
	return <img src={btn_page_nnext_n} width="36px" height="36px" alt={'last page icon'} />;
};

export const EditIcon = () => {
	return <img src={tab_icon1_n} width="20px" height="20px" alt={'edit icon'} />;
};

export const EditIconActive = () => {
	return <img src={tab_icon1_s} width="20px" height="20px" alt={'edit icon'} />;
};

export const CheckIcon = () => {
	return <img src={tab_icon2_n} width="20px" height="20px" alt={'check icon'} />;
};

export const CheckIconActive = () => {
	return <img src={tab_icon2_s} width="20px" height="20px" alt={'check icon'} />;
};

export const LibraryIcon = () => {
	return <img src={icon_library} width="36px" height="36px" alt={'library icon'} />;
};

export const PencilIcon = () => {
	return <img src={icon_card_1} width="20px" height="20px" alt={'pencil icon'} />;
};

export const DecisionTabIcon = () => {
	return <img src={icon_card_2} width="20px" height="20px" alt={'decision tab icon'} />;
};

export const SpeechBubbleIcon = () => {
	return <img src={icon_card_3} width="36px" height="36px" alt={'speech bubble icon'} />;
};

export const OpinionLibraryIcon = () => {
	return <img src={icon_card_4} width="20px" height="20px" alt={'opinion Library icon'} />;
};

export const RequestIcon = () => {
	return <img src={icon_request} width="16px" height="16px" alt={'request icon'} />;
};

export const CompleteIcon = () => {
	return <img src={icon_complete} width="16px" height="16px" alt={'complete icon'} />;
};

export const SelectIcon = () => {
	return <img src={btn_input_n} width="28px" height="28px" alt={'select icon'} />;
};

export const SelectIconActive = () => {
	return <img src={btn_input_p} width="28px" height="28px" alt={'select icon'} />;
};

export const CalenderIcon = () => {
	return <img src={calendar_n} width="22px" height="22px" alt={'calender icon'} />;
};

export const ZoomInIcon = (props) => {
	return (
		<img
			src={btn_zoomin_n}
			width={props?.width ? props.width : '24px'}
			height={props?.height ? props.height : '24px'}
			alt={'zoomin icon'}
		/>
	);
};

export const ZoomInIconActive = (props) => {
	return (
		<img
			src={btn_zoomin_p}
			width={props?.width ? props.width : '24px'}
			height={props?.height ? props.height : '24px'}
			alt={'zoomin active icon'}
		/>
	);
};

export const ZoomOutIcon = (props) => {
	return (
		<img
			src={btn_zoomout_n}
			width={props?.width ? props.width : '24px'}
			height={props?.height ? props.height : '24px'}
			alt={'zoomout icon'}
		/>
	);
};

export const ZoomOutIconActive = (props) => {
	return (
		<img
			src={btn_zoomout_p}
			width={props?.width ? props.width : '24px'}
			height={props?.height ? props.height : '24px'}
			alt={'zoomout active icon'}
		/>
	);
};

export const CheckboxIcon = () => {
	return <img src={btn_check_n} width="20px" height="20px" alt={'check icon'} />;
};

export const CheckboxIconActive = () => {
	return <img src={btn_check_s} width="20px" height="20px" alt={'check active icon'} />;
};

export const IconPopup1 = () => {
	return <img src={icon_popup_1} width="20px" height="20px" alt={'popup 1 icon'} />;
};

export const IconPopup2 = () => {
	return <img src={icon_popup_2} width="20px" height="20px" alt={'popup 2 icon'} />;
};

export const DownIcon = () => {
	return <img src={btn_down_n} width="30px" height="30px" alt={'down icon'} />;
};

export const DownIconActive = () => {
	return <img src={btn_down_p} width="30px" height="30px" alt={'down active icon'} />;
};

export const GravityIcon = () => {
	return <img src={btn_gravity_n} width="24px" height="24px" alt={'Gravity icon'} />;
};

export const GravityIconActive = () => {
	return <img src={btn_gravity_s} width="24px" height="24px" alt={'Gravity active icon'} />;
};

export const MicroscopeIcon = () => {
	return <img src={icon_micro} width="16px" height="16px" alt={'Microscope icon'} />;
};

export const AllImageIcon = () => {
	return <img src={icon_box} width="16px" height="16px" alt={'all box icon'} />;
};

export const NextIcon = () => {
	return <img src={btn_next_n} width="26px" height="26px" alt={'next arrow icon'} />;
};

export const NextIconActive = () => {
	return <img src={btn_next_s} width="26px" height="26px" alt={'next arrow active icon'} />;
};
