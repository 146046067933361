import axios from 'axios';
import EncryptUtil from './EncryptUtil';

var AxiosUtil = typeof exports === 'undefined' ? function numeric() {} : exports;
if (typeof global !== 'undefined') {
	global.AxiosUtil = AxiosUtil;
}

const instance = axios.create({
	baseURL: '/',
	validateStatus: (status) => status,
});

AxiosUtil.Get = function Get(service, url, params) {
	return instance
		.get(service + url, params)
		.then((res) => {
			if (res.status === 401) {
				window.location.href = `${
					process.env.REACT_APP_PORTAL
				}/login?redirect=${EncryptUtil.fctEncodeToHex(window.location.href)}`;
			}
			return { status: res.status, data: res.data };
		})
		.catch((res) => console.log(res));
};

AxiosUtil.Put = function Put(service, url, params) {
	return instance
		.put(service + url, params, {
			headers: {
				'Content-Type': `application/json`,
			},
		})
		.then((res) => {
			if (res.status === 401) {
				window.location.href = `${
					process.env.REACT_APP_PORTAL
				}/login?redirect=${EncryptUtil.fctEncodeToHex(window.location.href)}`;
			}
			return { status: res.status, data: res.data };
		})
		.catch((res) => console.log(res));
};

AxiosUtil.Post = function Post(service, url, params) {
	return instance
		.post(service + url, params, {
			headers: {
				'Content-Type': `application/json`,
			},
		})
		.then((res) => {
			if (res.status === 401) {
				window.location.href = `${
					process.env.REACT_APP_PORTAL
				}/login?redirect=${EncryptUtil.fctEncodeToHex(window.location.href)}`;
			}
			return { status: res.status, data: res.data };
		})
		.catch((res) => console.log(res));
};

AxiosUtil.Delete = function Delete(service, url, params) {
	return instance
		.delete(service + url, params, {
			headers: {
				'Content-Type': `application/json`,
			},
		})
		.then((res) => {
			if (res.status === 401) {
				window.location.href = `${
					process.env.REACT_APP_PORTAL
				}/login?redirect=${EncryptUtil.fctEncodeToHex(window.location.href)}`;
			}
			return { status: res.status, data: res.data };
		})
		.catch((res) => console.log(res));
};

AxiosUtil.Patch = function Patch(service, url, params) {
	return instance
		.patch(service + url, params, {
			headers: {
				'Content-Type': `application/json`,
			},
		})
		.then((res) => {
			if (res.status === 401) {
				window.location.href = `${
					process.env.REACT_APP_PORTAL
				}/login?redirect=${EncryptUtil.fctEncodeToHex(window.location.href)}`;
			}
			return { status: res.status, data: res.data };
		})
		.catch((res) => console.log(res));
};

export default AxiosUtil;
