import {
	PageFisrtIcon,
	PageLastIcon,
	PageNextIcon,
	PagePreviousIcon,
} from '../ui-component/icons/icons';
import fontLoading from './fonts';
import { renderTimeViewClock } from '@mui/x-date-pickers';

export default function componentStyleOverrides(theme) {
	const bgColor = '#FFF';
	const borderRadius = theme.defaultBorderRadius;
	const fontFamily = `'S-Core Dream', 'Roboto'`;

	return {
		MuiCssBaseline: {
			styleOverrides: fontLoading,
		},
		MuiButton: {
			defaultProps: {
				variant: 'contained',
				disableElevation: true,
				disableRipple: true,
			},
			styleOverrides: {
				root: {
					textTransform: 'none',
					alignSelf: 'center',
					borderRadius: borderRadius,
					minWidth: '3.5rem',
					fontWeight: 400,
					padding: '1 0.6rem',
					lineHeight: '1rem',
				},
				// 		sizeSmall: {
				// 			height: '1.6rem',
				// 		},
				// 		sizeMedium: {
				// 			height: '2.2rem',
				// 		},
				// 		sizeLarge: {
				// 			height: '2.6rem',
				// 			minWidth: '4rem',
				// 			padding: '0 0.8rem',
				// 		},
				// 		textSizeLarge: {
				// 			fontWeight: 500,
				// 		},
				// contained: {
				// 	backgroundColor: theme.colors?.primaryMain,
				// 	color: '#FFF',
				// 	'&:hover': {
				// 		backgroundColor: theme.colors?.primary100,
				// 	},
				// },
				// 		containedPrimary: {
				// 			// backgroundColor: theme.colors?.primaryMain,
				// 			// '&:hover': {
				// 			// 	backgroundColor: theme.colors?.primaryMain,
				// 			// },
				// 		},
			},
		},
		MuiPaper: {
			defaultProps: {
				elevation: 0,
			},
			styleOverrides: {
				root: {
					backgroundImage: 'none',
					fontFamily: fontFamily,
				},
				rounded: {
					borderRadius: borderRadius,
				},
			},
		},
		MuiCardHeader: {
			styleOverrides: {
				root: {
					color: theme.colors?.textDark,
					padding: '24px',
				},
				title: {},
			},
		},
		MuiCardContent: {
			styleOverrides: {
				root: {
					padding: '24px',
				},
			},
		},
		MuiCardActions: {
			styleOverrides: {
				root: {
					padding: '24px',
				},
			},
		},
		MuiListItemButton: {
			styleOverrides: {
				root: {
					color: theme.darkTextPrimary,
					padding: '1rem 0 1rem 1.2rem',
					borderRadius: borderRadius,
					marginBottom: '0.2rem',
					alignItems: 'flex-start',
					'&.Mui-selected': {
						color: theme.menuSelected,
						backgroundColor: theme.menuSelectedBack,
						'&:hover': {
							backgroundColor: theme.menuSelectedBack,
						},
						'& .MuiListItemIcon-root': {
							color: theme.menuSelected,
						},
					},
					'&:hover': {
						backgroundColor: theme.menuSelectedBack,
						color: theme.menuSelected,
						'& .MuiListItemIcon-root': {
							color: theme.menuSelected,
						},
					},
				},
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					color: theme.darkTextPrimary,
					minWidth: '36px',
					margin: 'auto 0.8rem auto 0',
				},
			},
		},
		MuiListItemText: {
			styleOverrides: {
				primary: {
					color: theme.textDark,
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: { fontFamily: fontFamily, fontSize: '14px' },
			},
		},
		MuiInputBase: {
			defaultProps: {
				autoComplete: 'off',
			},
			styleOverrides: {
				input: {
					padding: '0',
					fontSize: '0.875rem',
					color: '#a0a0a0',
					'&:focus': {
						color: '#212121',
					},
					'&::placeholder': {
						color: theme.darkTextSecondary,
					},
					/* Firefox에 대한 스크롤바 스타일 설정 */
					'&::-webkit-scrollbar': {
						width: '8px' /* 스크롤바의 너비 */,
					},

					'&::-webkit-scrollbar-track': {
						backgroundColor: '#E3E3E3' /* 트랙의 배경색 */,
					},

					'&::-webkit-scrollbar-thumb': {
						backgroundColor: '#B0B0B0' /* 핸들의 배경색 */,
						borderRadius: '6px' /* 핸들의 모서리를 둥글게 */,
					},

					'&::-webkit-scrollbar-thumb:hover': {
						backgroundColor: '#B0B0B0' /* 사용 중일 때의 배경색 변경 */,
					},
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					background: bgColor,
					borderRadius: borderRadius,
					'&:hover $notchedOutline': {
						borderColor: theme.colors?.primary300,
					},
					'&.MuiInputBase-multiline': {
						padding: 1,
					},
				},
				input: {
					fontWeight: 400,
					background: bgColor,
					color: 'black',
					padding: '8px',
					borderRadius: borderRadius,
					fontFamily: fontFamily,
					'&.MuiInputBase-inputSizeSmall': {
						padding: '7px 0px',
						'&.MuiInputBase-inputAdornedStart': {
							paddingLeft: 0,
						},
					},
					'&::placeholder': {
						textOverflow: 'ellipsis !important',
						fontFamily: fontFamily,
						fontWeight: 500,
					},
				},
				inputAdornedStart: {
					paddingLeft: 4,
				},
				notchedOutline: {
					border: `2px solid #efefef`,
					borderRadius: borderRadius,
				},
			},
		},
		MuiBackdrop: {
			styleOverrides: {
				root: {
					backgroundColor: 'rgba(0,0,0,0.6)',
				},
			},
		},
		// MuiRadio: {
		//     defaultProps: {
		//         icon: RadioIcon({ checked: false }),
		//         checkedIcon: RadioIcon({ checked: true }),
		//     },
		//     styleOverrides: {
		//         root: {
		//             padding: '0 6px 0 8px',
		//             marginRight: '6px',
		//             '&.Mui-disabled': {
		//                 width: '44px',
		//                 height: '30px',
		//                 backgroundSize: '30px 30px',
		//                 backgroundPosition: 'center',
		//                 backgroundRepeat: 'no-repeat',
		//                 // backgroundImage: `url(${btn_radio_d})`,
		//                 '& > img': {
		//                     display: 'none',
		//                 },
		//             },
		//         },
		//     },
		// },
		// MuiCheckbox: {
		// 	defaultProps: {
		// 		icon: CheckboxIcon({ checked: false }),
		// 		checkedIcon: CheckboxIcon({ checked: true }),
		// 	},
		// 	styleOverrides: {
		// 		root: {
		// 			'&.Mui-disabled': {
		// 				width: '48px',
		// 				height: '48px',
		// 				backgroundSize: '30px 30px',
		// 				backgroundPosition: 'center',
		// 				backgroundRepeat: 'no-repeat',
		// 				// backgroundImage: `url(${btn_check_d})`,
		// 				'& > img': {
		// 					display: 'none',
		// 				},
		// 			},
		// 		},
		// 	},
		// },
		MuiSlider: {
			styleOverrides: {
				root: {
					'&.Mui-disabled': {
						color: theme.colors?.grey450,
					},
				},
				mark: {
					backgroundColor: theme.paper,
					width: '4px',
				},
				valueLabel: {
					color: theme?.colors?.primaryMain,
				},
			},
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					borderColor: theme.divider,
					opacity: 1,
				},
			},
		},
		MuiAvatar: {
			styleOverrides: {
				root: {
					color: theme.colors?.primaryMain,
					background: theme.colors?.primary200,
				},
			},
		},
		MuiChip: {
			styleOverrides: {
				root: {
					'&.MuiChip-deletable .MuiChip-deleteIcon': {
						color: 'inherit',
					},
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					padding: '8px 8px 8px 14px',
					background: '#FFF',
					color: theme.textDark,
					fontWeight: 400,
					border: `1px solid ${theme.colors?.grey450}`,
					borderRadius: borderRadius,
					whiteSpace: 'pre-line',
					wordBreak: 'keep-all',
					maxWidth: 370,
				},
				arrow: {
					color: '#FFF',
					'&:before': {
						border: `1px solid ${theme.colors?.grey450}`,
					},
				},
			},
		},
		MuiDialog: {
			defaultProps: {
				fullWidth: true,
				maxWidth: 'xl',
				transitionDuration: 0,
			},
			styleOverrides: {
				paper: {
					background: '#FFF',
				},
			},
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					fontWeight: 600,
					padding: '1.5rem 1.6rem',
				},
			},
		},
		MuiDialogContent: {
			styleOverrides: {
				root: {
					padding: '0 1.6rem',
				},
			},
		},
		MuiDialogActions: {
			styleOverrides: {
				root: {
					justifyContent: 'center',
					padding: '1.6rem 0',
					'& > button.MuiButton-sizeMedium ': {
						minWidth: '5.8rem',
						height: '2.5rem',
						margin: '0 1rem',
					},
					'& > button.MuiButton-sizeSmall ': {
						minWidth: '5.2rem',
						height: '2.2rem',
					},
				},
			},
		},
		MuiSvgIcon: {
			styleOverrides: {
				root: {},
			},
		},
		MuiDataGrid: {
			defaultProps: {
				density: 'compact',
				editMode: 'row',
				columnHeaderHeight: 88,
				rowHeight: 88,
			},
			styleOverrides: {
				root: {
					color: '#ffffff',
					border: 'none',
					/* Firefox에 대한 스크롤바 스타일 설정 */
					'& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
						width: '8px' /* 스크롤바의 너비 */,
					},

					'& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
						backgroundColor: '#E3E3E3' /* 트랙의 배경색 */,
					},

					'& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
						backgroundColor: '#B0B0B0' /* 핸들의 배경색 */,
						borderRadius: '6px' /* 핸들의 모서리를 둥글게 */,
					},

					'& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
						backgroundColor: '#B0B0B0' /* 사용 중일 때의 배경색 변경 */,
					},
				},
				sortIcon: {
					opacity: '1 !important',
					fill: 'white',
				},
				cell: {
					// justifyContent: 'center',
					'&:focus': {
						outline: 'none',
					},
				},
				columnHeaders: {
					backgroundColor: theme.colors?.grey200,
					border: 'none',
					'& .MuiDataGrid-columnSeparator': {
						visibility: 'hidden',
					},
				},
				columnHeader: {
					'&:focus': {
						outline: 'none',
					},
					'& .MuiDataGrid-sortIcon': {
						fill: 'white',
					},
					'& .MuiIconButton-root': { color: 'white' },
				},
				columnHeaderTitleContainer: {
					// justifyContent: 'center',
				},
				row: {
					'&:hover': {
						backgroundColor: theme.colors?.primary400, // 마우스 호버 시 배경색
					},
					'.custom-row-style': {
						backgroundColor: theme.colors?.grey150, // 완료 시 색상
					},
					'&:hover .custom-row-style': {
						borderBottom: `1px solid ${theme.colors?.primaryMain} !important`,
					},
					'&:focus-within': {
						backgroundColor: theme.colors?.primary400, // mouse press 시 색상
					},
					'&.Mui-selected': {
						backgroundColor: theme.colors?.primary400, // 선택상태 시 색상
					},
					'&.Mui-selected .custom-row-style': {
						backgroundColor: theme.colors?.primary400, // 완료상태 선택 시 색상
					},
					'&.Mui-selected:hover': {
						backgroundColor: theme.colors?.primary400, // 선택상태 마우스 호버 시 색상
					},
					[`&.Custom-MuiDataGrid-Row--error`]: {
						backgroundColor: theme.colors?.errorLight,
					},
				},
				[`row--editing`]: {
					width: 'calc(100% - 1px)',
					minHeight: 'inherit',
					maxHeight: 'inherit',
					backgroundColor: '#FFF !important',
					outline: `2px solid ${theme.colors?.primaryMain}`,
					outlineOffset: '-2px',
					boxShadow: 'none',
					'& > .MuiDataGrid-cell': {
						backgroundColor: 'transparent',
					},
				},
				[`cell--editing`]: {
					outline: 'none !important',
					padding: '2px 10px !important',
					'& > .MuiInputBase-root': {
						border: `1px solid ${theme.colors?.line}`,
						borderRadius: borderRadius,
						'& > input': {
							textAlign: 'center',
						},
						'& > .MuiSelect-select': {
							minWidth: '1rem',
							padding: '0px 0px 1px 12px',
							margin: '1px',
						},
						'& > fieldset': {
							border: 'none',
						},
					},
				},
				footerContainer: {
					border: 'none',
				},
			},
		},
		MuiPagination: {
			defaultProps: {
				color: 'primary',
			},
		},
		MuiPaginationItem: {
			defaultProps: {
				components: {
					first: PageFisrtIcon,
					last: PageLastIcon,
					next: PageNextIcon,
					previous: PagePreviousIcon,
				},
			},
			styleOverrides: {
				root: {
					color: theme.colors?.grey450,
					borderRadius: borderRadius,
				},
				previousNext: {
					padding: 0,
					'&:hover': {
						backgroundColor: theme.colors?.page,
					},
				},
				firstLast: {
					padding: 0,
					'&:hover': {
						backgroundColor: theme.colors?.page,
					},
				},
			},
		},
		MuiLink: {
			styleOverrides: {
				root: {
					color: theme.colors?.primaryMain,
				},
			},
		},
		MuiTableCell: {
			defaultProps: {
				align: 'center',
			},
			styleOverrides: {
				root: {
					padding: '14px 6px',
					borderBottom: '2px solid #e9eaee',
				},
				head: {
					padding: '14px 6px',
					backgroundColor: theme.colors?.page,
				},
				sizeSmall: {
					lineHeight: '1.3rem',
					padding: '4px 16px',
					height: '2.4rem',
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				select: {
					'&.MuiSelect-select MuiSelect-outlined MuiInputBase-input MuiOutlinedInput-input css-x415wn-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':
						{ paddingRight: '0' },
					minWidth: '5.6rem',
					padding: '7px 12px',
				},
			},
		},
		MuiToggleButton: {
			styleOverrides: {
				root: {
					border: 'none',
					padding: '1px',
					backgroundColor: 'transparent !important',
				},
			},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				label: {},
			},
		},
		MuiDesktopTimePicker: {
			defaultProps: {
				viewRenderers: {
					hours: renderTimeViewClock,
					minutes: renderTimeViewClock,
					seconds: renderTimeViewClock,
				},
			},
		},
	};
}
